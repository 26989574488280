interface TextFieldStringProps {
  label: string;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  type?: string;
  disableCut?: boolean;
  disablePaste?: boolean;
  errorMessage?: string | null; // Propiedad opcional para el mensaje de error
  disabled?: boolean;

}

const TextFieldString: React.FC<TextFieldStringProps> = ({
  label,
  value,
  onChange,
  title,
  type = 'text',
  disableCut,
  disablePaste,
  errorMessage,
  disabled
}) => {
  // Función para evitar el cortar o pegar
  const handlePreventAction = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  return (
    <div className="flex flex-col mb-4 w-full">
      <label className="mb-1 text-m">{label}</label>
      <input
        type={type}
        title={title}
        value={value}
        onChange={onChange}
        onCut={disableCut ? handlePreventAction : undefined}
        onPaste={disablePaste ? handlePreventAction : undefined}
        disabled={disabled}
        className={`border ${errorMessage ? 'border-red-600' : 'border-gray-300'} ${disabled ? 'bg-white ' : 'bg-gray-200'}  rounded-md py-2 px-3 text-m desktop:min-w-[10rem] desktopL:min-w-[10rem] mobile:w-full`}
      />
      {errorMessage && (
        <span className="text-red-600 text-sm mt-1">{errorMessage}</span>
      )}
    </div>
  );
};

export default TextFieldString;
