import React from 'react';

interface HeaderSurveysProps {
  title: string;
  description?: string;
}

const HeaderSurveys: React.FC<HeaderSurveysProps> = ({ title, description }) => (
  <div className="flex justify-center text-center min-h-[128px] mobile:h-auto bg-[#fdfced] rounded-t-lg">
    <div className="flex justify-center items-center p-4">
      <div className='grid'>
        <h1 className="mobile:text-2xl text-4xl font-rounded font-semibold text-primaryOrange">
          {title}
        </h1>
        {
          description ? (
            <div className='flex w-full  text-center justify-center text-sm text-secondaryBrown my-2'>{description}</div>
          ) : (
            <div className="flex text-xl justify-center text-secondaryBrown mt-2">
              <span>Ingrese los siguientes datos</span>
            </div>

          )
        }
      </div>
    </div>
  </div>
);

export default HeaderSurveys;
