import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CancelButton from '../CancelButton';

interface EvidenceDetails {
  id: number;
  item_name: string;
  url: string;
  created_at: Date;
  minimum_standards: number;
}

interface EvidencePlansModalProps {
  open: boolean;
  onClose: () => void;
  details: EvidenceDetails[] | null;
  item?: string | undefined;
  activity?: string | undefined;
}

const EvidencePlansModal: React.FC<EvidencePlansModalProps> = ({ open, onClose, details, item, activity }) => {

  // Función para determinar el tipo de archivo basado en la extensión
  const getFileType = (url: string) => {
    const extension = url.split('.').pop()?.toLowerCase();
    if (!extension) return 'unsupported';

    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
      return 'image';
    }
    if (['mp4', 'webm', 'ogg'].includes(extension)) {
      return 'video';
    }
    if (extension === 'pdf') {
      return 'pdf';
    }
    if (['xlsx', 'xls', 'csv', 'doc', 'docx'].includes(extension)) {
      return 'document';
    }
    return 'unsupported';
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        maxWidth: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #704104',
        boxShadow: 24,
        p: 4,
        minHeight: '40vh',
        maxHeight: '90vh',
        overflowY: 'auto',
        display: 'grid',
      }}>
        {item &&
          <div className='text-center text-xl font-semibold py-3 text-secondaryBrown'>
            Evidencia para el item : {item}
          </div>
        }
        {activity &&
          <div className='text-center text-xl font-semibold py-3 text-secondaryBrown'>
            Evidencia para la actividad : {activity}
          </div>
        }
        {details && details.length > 0 ? (
          <div className="grid grid-cols-3 gap-4 text-center place-content-center items-center my-3">
            {details.map((detail, index) => {
              const fileType = getFileType(detail.url);
              return (
                <div key={index} className="col-span-1">
                  {fileType === 'image' ? (
                    <div>
                      <img src={detail.url} alt={`Evidence ${index + 1}`} className="w-full h-auto" />
                      <a href={detail.url} className='text-red-400 m-2' target='_blank' rel="noopener noreferrer">Ver imagen</a>
                    </div>
                  ) : fileType === 'video' ? (
                    <div>
                      <video controls className="w-full h-60">
                        <source src={detail.url} type={`video/${detail.url.split('.').pop()}`} />
                        Tu navegador no soporta el video.
                      </video>
                      <a href={detail.url} className='text-red-400 m-2' target='_blank' rel="noopener noreferrer">Ver video</a>
                    </div>
                  ) : fileType === 'pdf' ? (
                    <div>
                      <embed src={detail.url} width="100%" height="200px" type="application/pdf" />
                      <a href={detail.url} className='text-red-400 m-2' target='_blank' rel="noopener noreferrer">Ver PDF</a>
                    </div>
                  ) : fileType === 'document' ? (
                    <div>
                      <a href={detail.url} className='text-blue-500 m-2' target='_blank' rel="noopener noreferrer">Descargar documento</a>
                    </div>
                  ) : (
                    <p>Tipo de archivo no soportado</p>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div className='text-lg text-center'>
            No hay evidencias disponibles.
          </div>
        )}
        <div className='text-center mt-4'>
          <CancelButton
            onClick={onClose}
            text="Cerrar"
          />
        </div>
      </Box>
    </Modal>
  );
}

export default EvidencePlansModal;
