import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || '';

interface EmployeeResponse {
  message: string;
}

export class EmployeesData {
  first_name: string;
  last_name: string;
  id_card: string;
  phone: string;
  email: string;
  role: string;
  job_description?: string;
  job_code?: number;

  constructor(
    first_name: string,
    last_name: string,
    id_card: string,
    phone: string,
    email: string,
    role: string,
    job_description?: string,
    job_code?: number

  ) {
    this.first_name = first_name;
    this.last_name = last_name;
    this.id_card = id_card;
    this.phone = phone;
    this.email = email;
    this.role = role;
    this.job_description = job_description;
    this.job_code = job_code;
  }
}

export class EmployeesBatchData {
  employees: EmployeesData[];

  constructor(employees: EmployeesData[]) {
    this.employees = employees;
  }
}

type OnSuccess = (response: EmployeeResponse) => void;
type OnError = (error: any) => void;

const registerEmployee = (
  employeesBatchData: EmployeesBatchData,
  onSuccess: OnSuccess,
  onError: OnError
): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.post<EmployeeResponse>(
    `${API_URL}/companies/auth/register/employees`,
    JSON.stringify(employeesBatchData),
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
        'Content-Type': 'application/json',
        'X-Company-Id': companyId
      },
    }
  )
    .then(response => onSuccess(response.data))
    .catch(error => {
      if (error.response && error.response.status === 409) {
        const errorMessage = error.response.data.error;
        const conflicts = error.response.data.conflictos;


        onError({ message: errorMessage, conflicts });
      } else {
        onError({ message: "Error al crear empleado" });
      }
    });
};


const updateEmployee = (
  id: number, // ID del empleado
  data: any, // Objeto con los datos del empleado a actualizar
  onSuccess: OnSuccess,
  onError: OnError
): void => {
  const companyId = localStorage.getItem('company_id') ?? '';
  axios.put<EmployeeResponse>(
    `${API_URL}/companies/auth/update/employee/${id}`,
    data,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
        'Content-Type': 'application/json',
        'X-Company-Id': companyId
      },
    }
  )
    .then(response => onSuccess(response.data))
    .catch(error => onError(error));
};

export interface EmployeesListData {
  id: number;
  first_name: string;
  last_name: string;
  id_card: string;
  phone: string;
  email: string;
  role: string;
  role_es: string;
  status: string;
  job_description: string;
  job_code: number;
  survey_token: string;
  company: number;
}
interface responseEmployeesData {
  results: EmployeesListData[];
  count: number;
  next: string;
  previous: string;
}

const employeesData = (
  onSuccess: (response: responseEmployeesData) => void,
  onError: (error: any) => void,
  url?: string,
  idCard?: string,
  limit?: number,
  filter?: string,
  firstName?: string,
  lastName?: string,
): void => {
  let requestUrl = url || `${API_URL}/companies/auth/employees?offset=0`;

  if (limit !== undefined) {
    requestUrl += `&limit=${limit}`;
  }

  if (filter) {
    requestUrl += `&filter=${filter}`;
  }

  const params: any = {};
  if (idCard) {
    params.id_card = idCard;
  }

  if (firstName) {
    params.first_name = firstName;
  }
  if (lastName) {
    params.last_name = lastName;
  }

  const companyId = localStorage.getItem('company_id') ?? '';

  axios.get<responseEmployeesData>(requestUrl, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json',
      'X-Company-Id': companyId
    },
    params,
  })
    .then(response => onSuccess(response.data))
    .catch(error => onError(error));
};


const uploadExcelFile = (file: File, onSuccess: OnSuccess, onError: (errorMessages: string[]) => void): void => {
  const formData = new FormData();
  formData.append('file', file);

  const companyId = localStorage.getItem('company_id') ?? '';
  axios.post<EmployeeResponse>(`${API_URL}/companies/employees/import`, formData, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'multipart/form-data',
      'X-Company-Id': companyId
    },
  })
    .then(response => onSuccess(response.data))
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessages: string[] = error.response.data.errors;
        onError(errorMessages);
      } else {
        onError(["Error al procesar el archivo."]);
      }
    });
};



export const employeesService = {
  registerEmployee,
  updateEmployee,
  employeesData,
  uploadExcelFile
};