import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import {
  Employee,
  PlanDataServiceInterfaceResponse,
} from "../../services/autoevaluationService";

interface ImprovementPlanDetailsModalProps {
  open: boolean;
  onClose: () => void;
  item: string | undefined;
  details: PlanDataServiceInterfaceResponse[] | undefined;
}

export const formatDate = (dateString: string | undefined) => {
  if (!dateString) return "Fecha no disponible";
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(dateString).toLocaleDateString("es-ES", options);
};

export const formatDateEvents = (dateString: string | undefined) => {
  if (!dateString) return "Fecha no disponible";
  const options: Intl.DateTimeFormatOptions = { month: "short", day: "numeric" };
  return new Date(dateString).toLocaleDateString("es-ES", options);
};

const ImprovementPlanDetailsModal: React.FC<ImprovementPlanDetailsModalProps> = ({
  open,
  onClose,
  item,
  details,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          maxHeight: "600px",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle>Detalles del Plan de Mejora</DialogTitle>
      <DialogContent
        dividers
        sx={{
          overflowY: "auto",
          maxHeight: "350px",
        }}
      >
        <DialogContentText>
          {item ? <strong>Ítem:</strong> : "No se ha seleccionado ningún ítem."}{" "}
          {item}
        </DialogContentText>
        {details && details.length > 0 ? (
          details.map((detail, index) => (
            <div key={index} style={{ marginBottom: "16px" }}>
              <DialogContentText>
                <strong>Participantes:</strong>{" "}
                {detail.participants
                  ?.map(
                    (p: Employee) =>
                      `${p.first_name} ${p.last_name}`
                  )
                  .join(", ") || "No hay participantes disponibles."}
              </DialogContentText>
              <DialogContentText>
                <strong>Actividad:</strong> {detail.activity || "No disponible."}
              </DialogContentText>
              <DialogContentText>
                <strong>Responsable:</strong>{" "}
                {detail.manager
                  ? `${detail.manager.first_name} ${detail.manager.last_name}`
                  : "No hay responsable disponible."}
              </DialogContentText>
              <DialogContentText>
                <strong>Fecha de inicio:</strong> {formatDate(detail.start_date)}
              </DialogContentText>
              <DialogContentText>
                <strong>Fecha límite:</strong> {formatDate(detail.end_date)}
              </DialogContentText>
              <DialogContentText>
                <strong>Plan de trabajo:</strong>{" "}
                {detail.is_work_plan ? "Sí" : "No"}
              </DialogContentText>
              <DialogContentText>
                <strong>Plan de capacitación:</strong>{" "}
                {detail.is_training_plan ? "Sí" : "No"}
              </DialogContentText>
              <hr />
            </div>
          ))
        ) : (
          <DialogContentText>No hay detalles disponibles.</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImprovementPlanDetailsModal;
