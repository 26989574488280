import axios from 'axios';

const API_URL = "https://www.datos.gov.co/resource/xdk5-pm3f.json";

export interface Data {
    c_digo_dane_del_departamento: string;
    c_digo_dane_del_municipio: string;
    departamento: string;
    municipio: string;
}

type OnSuccess = (data: Data[]) => void;
type OnError = (error: any) => void;

const listDaneData = (onSuccess: OnSuccess, onError: OnError): void => {
    axios.get<Data[]>(API_URL)
        .then(response => {
            // Los siguientes municipios no aparecen en los datos del response.
            const cali: Data = {
                c_digo_dane_del_departamento: "76",
                departamento: "Valle del Cauca",
                c_digo_dane_del_municipio: "76001",
                municipio: "Cali",
            };
            const calarca: Data = {
                c_digo_dane_del_departamento: "63",
                departamento: "Quindío",
                c_digo_dane_del_municipio: "63130",
                municipio: "Calarcá"
            };
            const ibague: Data = {
                c_digo_dane_del_departamento: "73",
                departamento: "Tolima",
                c_digo_dane_del_municipio: "73001",
                municipio: "Ibague",
            };
            const updatedData = [calarca, cali, ibague, ...response.data];
            onSuccess(updatedData);
        })
        .catch(error => {
            onError(error);
        });
};

export const daneService = {
    listDaneData,
};
