import React, { useCallback, useEffect, useState } from 'react'
import { ImprovementPlanData, PlansDataInterfaceResponse, autoevaluationService } from '../../services/autoevaluationService'
import { standarsItemsTeam3 } from '../../utils/standarItems'
import { UploadEvidenceModal } from '../../components/improvementPlan/UploadEvidence';
import CreateTaskModal from '../../components/improvementPlan/SelectedItemModal';
import { customizedItems } from '../../utils/customizedItems';
import MainLayout from '../../components/MainLayout';
import ImprovementsPlansTable from '../../components/ImprovementsPlanTable';
import { columnsImprovementsPlans, createRowsCustomItems, createRowsStandarsItems } from '../../utils/rowsAndColumnsImprovementPlanTable';
import EditPlanModal, { PlanEdit } from '../../components/EditImprovementPlan';
import { EvidenceImprovementPlanDataResponse, GetEvidenceImprovementPlansByEmployee, uploadImageService } from '../../services/uploadImageServices';
import { EvidenceDetails } from '../workPlan/WorkPlan';
import EvidencePlansModal from '../../components/improvementPlan/EvidencePlansModal';
import Button from '../../components/Button';
import { useAppSelector } from '../../app/hooks';

type Props = {}

interface StandardItem {
  id: string;
  propName: string;
  text: string;
}


const TrainingPlan = (props: Props) => {
  const companyName = useAppSelector((state) => state.user.companyName);
  const companyId = useAppSelector((state) => state.user.companyId);
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState<number>(currentYear);
  const [selectedItem, setSelectedItem] = useState<StandardItem | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [showSelectItemModal, setShowSelectedItemModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<PlanEdit>();
  const [modalEditPlan, setModalEditPlan] = useState(false);
  const [evidenceDetails, setEvidenceDetails] = useState<EvidenceDetails[] | null>(null);
  const [selectedActivity, setSelectedActivity] = useState<string>('')
  const [plans, setPlans] = useState<PlansDataInterfaceResponse>({
    customized_items: [],
    standard_items: []
  });
  const [evidenceModalOpen, setEvidenceModalOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleUpdate = (updatedPlan: PlanEdit) => {
    const data: ImprovementPlanData = {
      state: updatedPlan.state,
      id: updatedPlan.id,
      activity: updatedPlan.activity,
      manager: updatedPlan.manager,
      participants: updatedPlan.participants,
      item_name: updatedPlan.item,
      start_date: updatedPlan.startDate ? (updatedPlan.startDate).toISOString() : "",
      end_date: updatedPlan.endDate ? (updatedPlan.endDate).toISOString() : "",
    };
    setErrorMessage(null)
    setSuccessMessage(null)
    autoevaluationService.putImprovementPlan(
      data,
      (response) => {
        fetchPlans()
        setModalEditPlan(false);
        setSuccessMessage("Plan de mejora actualizado correctamente.")
      },
      (error) => {
        console.error('Error al obtener los datos:', error);
        setErrorMessage("Error al actualizar el plan de mejora.")
      }
    );
  };

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage(null);
        setErrorMessage(null)
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleOpenModal = (item: string, id: string) => {
    let foundItem = standarsItemsTeam3.find(field => field.text === item);
    if (!foundItem) {
      foundItem = customizedItems.find(field => field.text === item);
    }

    if (foundItem) {
      const itemObj: StandardItem = { ...foundItem, id };
      setSelectedItem(itemObj);

      const propName = itemObj.propName;
      if (propName !== undefined) {
        setShowModal(true);
      }
    }
  };

  const handleImprovementPlanPdf = () => {
    autoevaluationService.improvementPlansPdf(
      (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'improvementPlans.pdf'); // Nombre del archivo a descargar
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      (error) => {
        console.error('Error al obtener los datos:', error);
      },
      "plan_de_capacitacion",
      year
    );
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(undefined);
  };

  const fetchPlans = useCallback(() => {
    autoevaluationService.getImprovementPlans(
      year,
      'training_plan',
      (response) => {
        setPlans(response);
      },
      (error) => {
        console.error('Error al obtener los datos:', error);
      }
    );
  }, [year]);

  useEffect(() => {
    fetchPlans()
  }, [fetchPlans, companyName, companyId])



  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYear(parseInt(event.target.value));
  };

  const handleCreateTask = () => {
    setShowSelectedItemModal(true);
  };

  const handleCloseCreateTaskModal = () => {
    fetchPlans();
    setShowSelectedItemModal(false);
  };
  const rowsCustomItems = createRowsCustomItems(plans);
  const rowsStandarItems = createRowsStandarsItems(plans);
  const handleEdit = (plan: PlanEdit) => {
    setSelectedPlan(plan);
    setModalEditPlan(true);
  };

  const handleOpenShowEvidence = (itemName: string, activity: string) => {
    setSelectedActivity(activity)

    uploadImageService.getEvidenceImprovementPlans(
      new GetEvidenceImprovementPlansByEmployee(itemName),
      (response: EvidenceImprovementPlanDataResponse[]) => {

        setEvidenceDetails(response);
        setEvidenceModalOpen(true);
      },
      (error) => {
        console.error('Error al obtener los datos:', error);
      },
      year
    );
    setShowSelectedItemModal(false);
    fetchPlans()
  };

  const handleDelete = (id: string) => {
    setErrorMessage(null)
    setSuccessMessage(null)
    autoevaluationService.deleteImprovementPlan(
      id,
      () => {
        setModalEditPlan(false);
        setSuccessMessage("Plan de capacitación eliminado correctamente.")
        fetchPlans()
      },
      (error) => {
        console.error('Error al obtener los datos:', error);
        setErrorMessage("Error al eliminar el plan de capacitación.")
      }
    );
  };

  return (
    <>
      <MainLayout>
        <div className='text-center text-secondaryBrown'>
          <h1 className="text-4xl text-start font-bold mb-4 text-primaryOrange">Planes de capacitación</h1>
          <div className=" flex justify-items-center text-center my-14 gap-10">
            <div className='flex justify-center items-center text-center gap-5'>
              <label htmlFor="year" className="block text-xl font-medium">Año: </label>
              <input
                id="year"
                name="year"
                type="number"
                value={year}
                onChange={handleYearChange}
                className="w-24 bg-secondaryGray p-1 justify-center text-center text-centerrounded-md block rounded-lg "
              />

            </div>
            <div className='flex gap-8'>
              <Button
                onClick={handleCreateTask}
                text="Crear plan"
              />

              <Button
                onClick={handleImprovementPlanPdf}
                text='Descargar pdf'
              />

            </div>
          </div>
          <span className='text-lg text-primaryGreen'>{successMessage}</span>
          <span className='text-lg text-primaryRed'>{errorMessage}</span>
          <div className='w-full'>
            <ImprovementsPlansTable
              title="items estándares"
              handleEdit={handleEdit}
              columns={columnsImprovementsPlans}
              rows={rowsStandarItems}
              page={page}
              rowsPerPage={rowsPerPage}
              handleOpenModal={handleOpenModal}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleOpenModalShowEvidence={handleOpenShowEvidence}
              handleDeleteClick={handleDelete}
            />
            {rowsCustomItems.length > 0 && (
              <ImprovementsPlansTable
                title="items extras"
                handleEdit={handleEdit}
                columns={columnsImprovementsPlans}
                rows={rowsCustomItems}
                page={page}
                rowsPerPage={rowsPerPage}
                handleOpenModal={handleOpenModal}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleOpenModalShowEvidence={handleOpenShowEvidence}
                handleDeleteClick={handleDelete}
              />
            )}

          </div>
        </div>
        <UploadEvidenceModal
          itemText={selectedItem?.text}
          propName={selectedItem?.propName}
          show={showModal}
          onClose={handleCloseModal}
          title='plan de capacitación'
          id={selectedItem?.id} />

        <EvidencePlansModal open={evidenceModalOpen} onClose={() => setEvidenceModalOpen(false)} details={evidenceDetails} activity={selectedActivity} />

        <CreateTaskModal
          open={showSelectItemModal}
          onClose={handleCloseCreateTaskModal}
        />
        {selectedPlan && (
          <EditPlanModal
            isOpen={modalEditPlan}
            onClose={() => setModalEditPlan(false)}
            plan={selectedPlan}
            onSave={handleUpdate}
          />
        )}

      </MainLayout>

    </>

  )
}

export default TrainingPlan