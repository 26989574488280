/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useState } from 'react';
import { companyService, CompanyData } from '../../services/companyServices';
import { daneService, Data } from '../../services/daneService';
import jsonDataRisk from '../../utils/riskClass.json'
import SuccessModal from '../../components/SuccessModal';
import Button from '../../components/Button';
import TextFieldString from '../../components/TextFieldString';
import HeaderSurveys from '../../components/HeaderSurveys';
import backupDownloadIcon from '../../assets/icons/backup-download-icon.svg'
import { mainDivClassName } from '../../utils/MainDivClassName';
import SelectInput from '../../components/SelectInput';
import SelectInputNumber from '../../components/SelectInputNumber';
import TextFieldNumber from '../../components/TextFieldNumber';
import Select from 'react-select';
import cuidamosLogo from '../../assets/icons/cuidamos-logo.png'
import { useDropzone } from 'react-dropzone';
import { EvidenceRequest, EvidenceResponse, uploadImageService, UploadRequest } from '../../services/uploadImageServices';
import MainLayout from '../../components/MainLayout';
import CancelButton from '../../components/CancelButton';
import { useAppSelector } from '../../app/hooks';



const risks = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 }
]

export const arls = [
    { label: "AXXA COLPATRIA", value: "AXXA COLPATRIA" },
    { label: "BOLIVAR", value: "BOLIVAR" },
    { label: "COLMENA", value: "COLMENA" },
    { label: "LA EQUIDAD", value: "LA EQUIDAD" },
    { label: "LIBERTY", value: "LIBERTY" },
    { label: "POSITIVA", value: "POSITIVA" },
    { label: "SURA", value: "SURA" },
    { label: "ALFA", value: "ALFA" },
    { label: "NO APLICA", value: "NO APLICA" },
];


interface CodesFromRisk {
    value: string;
    label: string;
}

const EditCompany = () => {
    const [logoFile, setLogoFile] = useState<File>();
    const [isEditing, setIsEditing] = useState<boolean>(true);
    const [changeLogo, setChangeLogo] = useState<boolean>(false)
    const [codes, setCodes] = useState<CodesFromRisk[]>([]);
    const companyId = useAppSelector((state) => state.user.companyId);
    const [companyData, setCompanyData] = useState({
        id: 0,
        name: '',
        nit: '',
        email: '',
        phone: '',
        city: '',
        address: '',
        arl: '',
        logo_url: '',
        activity_code: '',
        number_of_employees: 0,
        risk_level: '',
        state: '',
        user: 0,
        consultant: {}
    });

    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);

    const onDropLogo = useCallback((acceptedFiles: File[]) => {
        setLogoFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onDropLogo,
        multiple: false,
    });

    const options = codes.map((option) => ({
        value: option.value,
        label: option.label,
    }));

    useEffect(() => {
        const codesFromRisk = jsonDataRisk.filter
            (item => item.CLASE_DE_RIESGO === parseInt(companyData.risk_level)).map(item => ({
                value: item.CODIGO_CIIU,
                label: `${item.CODIGO_CIIU} - ${(item.ACTIVIDAD_ECONOMICA)?.toLowerCase()}`
            }));

        setCodes(codesFromRisk)

    }, [companyData.risk_level])


    const fetchMyCompany = () => {
        companyService.getMyCompany(
            (response) => {
                setCompanyData(response || {
                    id: 0,
                    name: '',
                    nit: '',
                    email: '',
                    phone: '',
                    city: '',
                    address: '',
                    arl: '',
                    logo_url: '',
                    activity_code: '',
                    number_of_employees: 0,
                    risk_level: '',
                    state: '',
                    user: 0,
                    consultant: {}
                });
            },
            () => {
                setErrorMessage('Error obteniendo los datos de la empresa');
            }
        );
    }

    const updateCompany = () => {
        companyService.updateCompany(
            companyData,
            (response) => {
                setShowModal(true)

            },
            () => {
                setErrorMessage("Error actualizando datos de la empresa")
            }
        );

    }
    useEffect(() => {
        fetchMyCompany()

    }, [companyId]);

    const handleClickImage = async (): Promise<string | null> => {
        if (logoFile === undefined) return null;
        try {
            setLoading(true);
            const generateUrlResponse = await generateUrlPromise(new EvidenceRequest(logoFile.name));

            await uploadImagePromise(logoFile, generateUrlResponse.url, generateUrlResponse.final_url);

            setLoading(false);
            return generateUrlResponse.final_url;

        } catch (err) {
            console.error('Error durante el procesamiento del archivo:', err);
            setErrorMessage('Error en el envío del logo de la empresa.');
            setLoading(false);
            return null;
        }
    };

    const handleChangeLogo = async () => {
        if (logoFile) {
            const logoUrl = await handleClickImage();

            if (logoUrl) {
                companyData.logo_url = logoUrl;
                console.log(companyData);

                updateCompany()
            }

        }

    };

    const generateUrlPromise = (request: EvidenceRequest): Promise<EvidenceResponse> => {
        return new Promise<EvidenceResponse>((resolve, reject) => {
            uploadImageService.generateLogoUrl(request, (response: EvidenceResponse) => {
                resolve(response);
            }, (error: any) => {
                reject(error);
            });
        });
    };

    const uploadImagePromise = (file: File, url: string, finalUrl: string) => {
        return new Promise<string>(async (resolve, reject) => {
            try {
                await uploadImageService.uploadImage(
                    new UploadRequest(file, url),
                    response => {
                        resolve(finalUrl);
                    },
                    error => {
                        setErrorMessage('Error al subir la imagen.');
                        reject(error);
                    }
                );
            } catch (error) {
                setErrorMessage('Error en el servicio de subida.');
                reject(error);
            }
        });
    };

    const handleCloseModal = () => {
        setIsEditing(true)
        setShowModal(false);
    };


    return (
        <MainLayout>
            {showModal && (
                <SuccessModal
                    message="Datos actualizados con éxito"
                    buttonText="Continuar"
                    onClose={handleCloseModal}
                />
            )}

            <div className={`grid items-center h-full mobile:h-auto ${mainDivClassName} p-2`}>
                <div className="bg-white w-full border-[1px] place-content-center">
                    <div className="grid grid-cols-3 mobile:grid-cols-1 p-4 gap-10 items-center text-secondaryBrown">

                        <div className="grid place-items-center gap-4">
                            <img src={companyData.logo_url ? companyData.logo_url : cuidamosLogo} width="34%" alt="Logo de la empresa" />
                            <div className='flex justify-center items-center gap-4'>
                                <Button
                                    text="Cambiar logo"
                                    onClick={() => setChangeLogo(true)}
                                    disabled={loading}
                                />
                                {companyData.logo_url && (
                                    <button
                                        onClick={() => {
                                            const link = document.createElement("a");
                                            link.href = companyData.logo_url; // URL de la imagen
                                            link.target = "_blank"; // Abrir en nueva pestaña
                                            link.click(); // Simular clic
                                        }}
                                        disabled={loading}
                                    ><img src={backupDownloadIcon} alt="" /></button>
                                )}

                            </div>

                        </div>
                        {changeLogo && (
                            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                                <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
                                    <div className="flex justify-between items-center mb-4">
                                        <h2 className="text-xl font-semibold">Cambiar logo</h2>
                                        <button onClick={() => { setChangeLogo(false) }} className="text-xl font-bold">X</button>
                                    </div>
                                    <div className='w-full flex flex-col mb-4'>
                                        <div className='flex w-full justify-between gap-4'>
                                            <div className='grid w-full'>
                                                <div
                                                    className="flex w-full justify-center items-center font-semibold bg-primaryOrange hover:bg-secondaryOrange min-w-[100px] h-[36px] text-center text-white rounded-md cursor-pointer"
                                                    {...getRootProps()}
                                                >
                                                    <input {...getInputProps()} />
                                                    {isDragActive ? <p>Suelta el logo aquí</p> : <p>Añade el logo</p>}
                                                </div>
                                                <div className="flex justify-center items-center mt-4">
                                                    {logoFile && (
                                                        <img
                                                            src={URL.createObjectURL(logoFile)}
                                                            alt="Logo preview"
                                                            style={{ width: '70px', height: '70px' }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-4 justify-center">
                                        <Button
                                            text='Guardar Logo'
                                            onClick={handleChangeLogo}
                                        />
                                        <CancelButton
                                            onClick={() => { setChangeLogo(false) }}
                                            text='Cerrar'
                                        />

                                    </div>
                                </div>
                            </div>
                        )

                        }
                        <TextFieldString
                            label="Razón Social"
                            value={companyData.name}
                            onChange={(e) =>
                                setCompanyData((prevData) => ({
                                    ...prevData,
                                    name: e.target.value,
                                }))
                            }
                            title="Nombre legal de tu empresa como está registrado oficialmente"
                            disabled={isEditing}
                        />

                        <TextFieldNumber
                            label="NIT"
                            value={parseInt(companyData.nit)}
                            onChange={(e) =>
                                setCompanyData((prevData) => ({
                                    ...prevData,
                                    nit: e.target.value,
                                }))
                            }
                            disabled={true}
                            title="Número de Identificación Tributaria de tu empresa. (Agrega el NIT sin dígito de verificación, sin letras y sin caracteres especiales)"
                        />

                        <SelectInput
                            label="ARL"
                            id="arl-company"
                            value={companyData.arl}
                            disabled={isEditing}
                            onChange={(e) =>
                                setCompanyData((prevData) => ({
                                    ...prevData,
                                    arl: e.target.value,
                                }))
                            }
                            options={arls}
                            title="Selecciona la clase de riesgo asociada a las actividades principales de tu empresa, de acuerdo con la tabla de clasificación de riesgos laborales"
                        />

                        <TextFieldString
                            label="Número de teléfono"
                            value={companyData.phone}
                            onChange={(e) =>
                                setCompanyData((prevData) => ({
                                    ...prevData,
                                    phone: e.target.value,
                                }))
                            }
                            disabled={isEditing}
                        />

                        <TextFieldString
                            label="Dirección"
                            value={companyData.address}
                            onChange={(e) =>
                                setCompanyData((prevData) => ({
                                    ...prevData,
                                    address: e.target.value,
                                }))
                            }
                            disabled={isEditing}
                            title="Proporciona la dirección completa de la ubicación principal de tu empresa"
                        />
                        <SelectInputNumber
                            label="Clase de riesgo"
                            id="risk-company"
                            value={parseInt(companyData.risk_level)}
                            onChange={(value: number) =>
                                setCompanyData((prevData) => ({
                                    ...prevData,
                                    risk_level: value.toString(),
                                }))
                            }
                            options={risks}
                            disabled={isEditing}
                            title="Selecciona la clase de riesgo asociada a las actividades principales de tu empresa, de acuerdo con la tabla de clasificación de riesgos laborales"
                        />

                        <TextFieldNumber
                            label="Número de empleados"
                            value={companyData.number_of_employees}
                            onChange={(e) =>
                                setCompanyData((prevData) => ({
                                    ...prevData,
                                    number_of_employees: e.target.valueAsNumber,
                                }))
                            }
                            disabled={isEditing}
                            title="Total de empleados que trabajan en tu empresa"
                        />

                        <div className="w-full flex flex-col mb-4">
                            <label className="mb-1 text-m text-center">
                                Código CIIU actividad económica principal de la empresa:
                            </label>
                            <Select
                                className="bg-secondaryGray rounded-md desktop:min-w-[10rem] desktopL:min-w-[10rem] w-full mobile:w-full text-m"
                                value={options.find(option => option.value === companyData.activity_code)}
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setCompanyData((prevData) => ({
                                            ...prevData,
                                            activity_code: selectedOption.value,
                                        }));
                                    }
                                }}
                                options={options}
                                placeholder="Selecciona..."
                                isSearchable={true}
                                noOptionsMessage={() => "No se encontraron opciones"}
                            />
                        </div>
                    </div>

                    <span className="animate-pulse text-center text-red-600 text-sm">
                        {errorMessage && <div>{errorMessage}</div>}
                    </span>

                    <div className="flex justify-center my-4">
                        {isEditing ? (
                            <Button
                                text={`${isEditing ? "Editar datos" : "Cancelar"}`}
                                onClick={() => { setIsEditing(!isEditing) }}
                                disabled={loading}
                            />

                        ) : (
                            <div className='flex gap-4'>
                                <Button
                                    text="Guardar cambios"
                                    onClick={updateCompany}
                                    disabled={loading}
                                />

                                <CancelButton
                                    text='Cancelar'
                                    onClick={() => setIsEditing(true)} />

                            </div>

                        )}

                    </div>
                </div>
            </div>
        </MainLayout>
    );

}
export default EditCompany
