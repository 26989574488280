import ItemMenu from './ItemMenu'
import homeIcon from '../../assets/icons/home-icon.svg'
import homeIconOpen from '../../assets/icons/home-icon-open.svg'
import absenteeismIcon from '../../assets/icons/absenteeism-icon.svg'
import absenteeismIconOpen from '../../assets/icons/absenteeism-icon-open.svg'
import workPlanIcon from '../../assets/icons/work-plan-icon.svg'
import workPlanIconOpen from '../../assets/icons/work-plan-icon-open.svg'
import trainingPlanIcon from '../../assets/icons/training-plan-icon.svg'
import trainingPlanIconOpen from '../../assets/icons/training-plan-icon-open.svg'
import employeesIcon from '../../assets/icons/employees-icon.svg'
import backupDownloadIcon from '../../assets/icons/backup-download-icon.svg'
import employeesIconOpen from '../../assets/icons/employees-icon-open.svg'
import logoutIcon from '../../assets/icons/logout-icon.svg'
import logoutIconOpen from '../../assets/icons/logout-icon-open.svg'
import check from '../../assets/icons/check-icon.svg'
import registerCompanyIcon from '../../assets/icons/register-company-icon.svg'
import registerCompanyOpenIcon from '../../assets/icons/register-company-open-icon.svg'
import connectWhatsappIcon from '../../assets/icons/connect-whatsapp-icon.svg'
import connectWhatsappOpenIcon from '../../assets/icons/connect-whatsapp-open-icon.svg'
import joinConsultantIcon from '../../assets/icons/join-consultant-icon.svg'
import joinConsultantOpenIcon from '../../assets/icons/join-consultant-open-icon.svg'
import consultantsCuidamosIcon from '../../assets/icons/consultants-cuidamos.svg'
import consultantsCuidamosOpenIcon from '../../assets/icons/consultants-cuidamos-open.svg'
import inspectionIcon from '../../assets/icons/inspection-icon.svg'
import inspectionIconOpen from '../../assets/icons/inspection-icon-open.svg'
import cuidamosLogo from '../../assets/icons/cuidamos-logo.png'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { authService } from '../../services/authServices';
import { setCompany, setLoggedIn } from '../../features/userSlice';
import { useEffect, useState, useRef } from 'react'
import plusIcon from '../../assets/icons/plus-icon.svg'
import { backupService } from '../../services/backupService'
import { CompanyDataResponse, consultantService } from '../../services/consultantServices'
import { companyService } from '../../services/companyServices'

const Sidebar = () => {
  const userState = useAppSelector((state) => state.user);
  const userType = userState.userType;
  const companyName = useAppSelector((state) => state.user.companyName);
  const companyId = useAppSelector((state) => state.user.companyId);
  const [backupStatus, setBackupStatus] = useState<string>('');
  const [backupId, setBackupId] = useState<string>()
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [errorBackup, setErrorBackup] = useState<string | undefined>();
  const [showDownloadWindow, setShowDownloadWindow] = useState<boolean>(false);
  const [showErrorWindow, setShowErrorWindow] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [companies, setCompanies] = useState<CompanyDataResponse[]>([])
  const [logo, setLogo] = useState<string | null>()
  const [errorMessage, setErrorMessage] = useState("")
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [position, setPosition] = useState<{ top: number, left: number }>({ top: 0, left: 0 });

  const downloadButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updatePosition = () => {
      if (downloadButtonRef.current) {
        const rect = downloadButtonRef.current.getBoundingClientRect();
        setPosition({
          top: rect.top,
          left: rect.left + rect.width
        });
      }
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);

    return () => window.removeEventListener('resize', updatePosition);
  }, [showDownloadWindow]);

  const completeLogout = () => {
    dispatch(setLoggedIn(false));
    localStorage.clear();
    navigate('/');
  };

  const handleLogout = () => {
    authService.logout(
      completeLogout,
      (err) => {
        if (err?.response?.status === 401) {
          completeLogout();
        } else {
          alert(`Error durante cierre de sesión: ${err.message || err}`);
        }
      }
    );
  };

  useEffect(() => {

    let intervalId: NodeJS.Timeout | null = null;

    if (backupId) {
      intervalId = setInterval(() => {
        backupService.checkBackupStatus(
          backupId,
          (data) => {
            setBackupStatus(data.status);
            if (data.status === "Error") {
              setErrorBackup(data.error)
              setShowErrorWindow(true);
              clearInterval(intervalId!);
            } else if (data.status === "Creado" && data.download_url) {
              setDownloadUrl(data.download_url);
              clearInterval(intervalId!);
              setShowDownloadWindow(true);
            }
          },
          (error) => {
            console.error('Error checking backup status:', error);
            setBackupStatus('failed');
            clearInterval(intervalId!);
            setShowErrorWindow(true);
          }
        );
      }, 2000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [backupId, companyId, companyName]);

  const handleBackup = () => {
    backupService.downloadBackup(
      (data) => {
        setBackupId(data.backup_id)
      },
      (error) => {
        console.error('Error downloading file:', error);
      }
    );
  };
  const handleSelectToggle = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleSelectOption = (companyName: string, companyId?: string) => {
    if (!companyId) {
      setErrorMessage("Error: ID de la empresa no válido.")
      return;
    }
    dispatch(setCompany({ companyId, companyName }));
    setShowDropdown(false)

  };

  useEffect(() => {
    if (userType === "consultant") {
      consultantService.getCompaniesData(
        (response) => setCompanies(response.results),
        (error) => setErrorMessage("Error al obtener los datos de las empresas"),
      );
    }

    companyService.getCompanyData(
      (response) => {
        setLogo(response.logo_url)
        console.log('Get company:', response.number_of_employees);
      },
      (error) => setErrorMessage("Error al buscar la company"),
    )
  }, [companyId, companyName])


  return <div className="z-50 bg-white mobile:hidden border-r-[1px] border-primaryOrange h-screen overflow-y-auto overflow-x-hidden ">
    <div >
      <div className="flex items-center justify-center p-4">
        <div className="relative flex justify-center w-20 h-20 rounded-full overflow-hidden bg-white items-center  text-center group">
          <button
            onClick={
              userType === 'consultant'
                ? handleSelectToggle
                : () => navigate('/profile')
            }

            className="relative w-full h-full text-sm"
          >
            <img
              src={logo ? logo : cuidamosLogo}
              alt="Logo de la empresa"
              className=" w-full h-full transition-opacity duration-300"
            />
            {userType === 'consultant' && (
              <>
                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>

                <span className="absolute inset-0 flex items-center justify-center text-white  font-bold opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  Cambiar Empresa
                </span>
              </>
            )}
          </button>
        </div>

        {userType === 'consultant' ? (
          <div className="relative grid place-content-center place-items-center">
            {showDropdown && (
              <div
                className="p-2 fixed min-w-[300px] max-w-[300px] max-h-[800px] bg-white text-s rounded-lg border-2 shadow-lg border-secondaryBrown z-[9999]"
                style={{
                  top: '80px',
                  left: '10%',
                  transform: 'translateX(-10%)',
                }}
              >
                {companies.map((company) => (
                  <button
                    key={company.id}
                    className="w-full text-left text-primaryOrange py-2 hover:bg-gray-200"
                    onClick={() => handleSelectOption(company.name, company.id)}
                  >
                    <div className='flex justify-start items-center gap-4 w-full rounded-xl '>
                      <img
                        src={company.logo_url ? company.logo_url : cuidamosLogo}
                        alt="Logo de la empresa"
                        className="w-8 h-8 transition-opacity duration-300"
                      />
                      <div className='flex w-full justify-between items-center'>
                        {company.name}

                        {companyName === company.name ? (
                          <div className='flex justify-end items-end '>
                            <img src={check} alt="" />

                          </div>
                        ) : null}
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>
        ) : null}
      </div>
      {(userType === 'company' || userType === 'consultant') && (
        <div className='flex flex-col justify-center gap-4 items-center'>
          <ItemMenu
            link="/"
            logo={homeIcon}
            selectedLogo={homeIconOpen}
            isSelected={location.pathname === '/' || location.pathname === '/home'}
          />
          <ItemMenu
            link="/absenteeism"
            logo={absenteeismIcon}
            selectedLogo={absenteeismIconOpen}
            isSelected={location.pathname === '/absenteeism'}
          />
          <ItemMenu
            link="/work-plan"
            logo={workPlanIcon}
            selectedLogo={workPlanIconOpen}
            isSelected={location.pathname === '/work-plan'}
          />
          <ItemMenu
            link="/training-plan"
            logo={trainingPlanIcon}
            selectedLogo={trainingPlanIconOpen}
            isSelected={location.pathname === '/training-plan'}
          />
          <ItemMenu
            link="/employees"
            logo={employeesIcon}
            selectedLogo={employeesIconOpen}
            isSelected={location.pathname === '/employees'}
          />
          <ItemMenu
            link="/evidence-home"
            logo={inspectionIcon}
            selectedLogo={inspectionIconOpen}
            isSelected={location.pathname === '/evidence'}
          />

          <div
            className='w-[130px] h-[100px] p-1 bg-secondaryGray'
            ref={downloadButtonRef}
          >
            <div className='flex flex-col justify-center items-center'>
              <button onClick={handleBackup}>
                <img src={backupDownloadIcon} alt="Imagen de descarga" />


              </button>
              <div className='text-sm text-center'>
                {backupStatus === 'Error' ? (
                  <div >
                    Backup falló
                  </div>
                ) : backupStatus === 'En progreso' ? (
                  <div>
                    Generando...
                  </div>
                ) : (
                  <>
                    {backupStatus === 'Creado' && downloadUrl ? (
                      <div className='grid text-center place-items-center justify-items-center '>
                        <span>Backup creado</span>
                        <button
                          className="ml-2"
                          onClick={() => setShowDownloadWindow(true)}
                        >
                          <img src={plusIcon} alt="" />

                        </button>
                      </div>
                    ) : (
                      <span>Descargar Backup</span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

      )}
      {(userType === 'admin') && (
        <div className='flex flex-col justify-center gap-4 items-center'>
          <ItemMenu
            link="/admin/register-company"
            logo={registerCompanyIcon}
            selectedLogo={registerCompanyOpenIcon}
            isSelected={location.pathname === '/admin/register-company'}
          />
          <ItemMenu
            link="/admin/connect-whatsapp"
            logo={connectWhatsappIcon}
            selectedLogo={connectWhatsappOpenIcon}
            isSelected={location.pathname === '/admin/connect-whatsapp'}
          />
          <ItemMenu
            link="/admin/join-consultant"
            logo={joinConsultantIcon}
            selectedLogo={joinConsultantOpenIcon}
            isSelected={location.pathname === '/admin/join-consultant'}
          />
          <ItemMenu
            link="/admin/list-consultants"
            logo={consultantsCuidamosIcon}
            selectedLogo={consultantsCuidamosOpenIcon}
            isSelected={location.pathname === '/admin/list-consultants'}
          />
        </div>
      )}

      <div className='flex flex-col justify-center items-center mt-4'>
        <ItemMenu
          link="/logout"
          logo={logoutIcon}
          selectedLogo={logoutIconOpen}
          isSelected={location.pathname === '/logout'}
          onClick={handleLogout}
        />
      </div>

    </div>
    {
      showDownloadWindow && downloadUrl && (
        <div className="absolute z-40 bg-white shadow-lg border border-secondaryBrown rounded-lg p-4 w-[250px]"
          style={{ top: position.top + 10, left: position.left + 10 }} >
          <div className="flex justify-around items-center">
            <a href={downloadUrl} download="backup.zip">
              <button className="text-primaryOrange">Descargar Backup</button>
            </a>
            <button
              onClick={() => setShowDownloadWindow(false)}
              className="text-red-500 font-bold ml-2"
            >
              x
            </button>
          </div>
        </div>
      )
    }

    {
      showErrorWindow && errorBackup && (
        <div className="absolute z-40 bg-white shadow-lg border border-secondaryBrown rounded-lg p-4 w-[250px]"
          style={{ top: position.top + 10, left: position.left + 10 }} >
          <div className="flex justify-between items-center">
            <span className="text-primaryRed">{errorBackup}</span>
            <button
              onClick={() => setShowErrorWindow(false)}
              className="text-red-500 font-bold ml-2"
            >
              x
            </button>
          </div>
        </div>
      )
    }

  </div >

}

export default Sidebar;
