import { useEffect, useState } from 'react';
import logoLaborus from '../../assets/icons/logoLaborus.svg';
import Notifications from '../Notifications';
import UserMenu from '../UserMenu';
import ItemTextNumber from '../ItemTextNumber';
import { companyService } from '../../services/companyServices';
import { useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';

type Props = {};

const Header = (props: Props) => {
  const companyName = useAppSelector((state) => state.user.companyName);
  const companyId = useAppSelector((state) => state.user.companyId);
  const [numEmployees, setNumEmployees] = useState<number>(0)
  const [riskLevel, setRiskLevel] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState("")
  const userState = useAppSelector((state) => state.user);
  const userType = userState.userType;
  const navigate = useNavigate()

  useEffect(() => {
    setErrorMessage("")
    companyService.getCompanyData(
      (response) => {
        console.log('Get company', response.number_of_employees);
        setRiskLevel(response.risk_level)
        setNumEmployees(response.number_of_employees)

      },
      (error) => {
        setErrorMessage("Error al buscar la company")
      },

    )

  }, [companyId, companyName])

  const getMinimumStandards = (numberEmployees: number, riskLevel: string) => {
    const risk = parseInt(riskLevel, 10);

    if (numberEmployees < 11 && [1, 2, 3].includes(risk)) {
      return 7;
    } else if (numberEmployees >= 11 && numberEmployees < 50 && [1, 2, 3].includes(risk)) {
      return 21;
    } else if (numberEmployees >= 50 && [1, 2, 3].includes(risk)) {
      return 60;
    } else if ([4, 5].includes(risk)) {
      return 60;
    }
    return 0;
  };


  return (
    <header className="grid  bg-[#fdfced] min-h-[217px]  text-center max-w-[1740px] top-0 z-10 border-b-2 border-[#70410433]">
      <div className="flex flex-wrap items-center justify-between w-full mx-4 mt-4">
        <div className="flex items-center gap-4">
          <div className="flex-col text-5xl text-start text-primaryOrange">
            <p className="mb-2">
              <strong>BIENVENIDO</strong>
            </p>
            <div className='flex gap-4'>
              <button
                onClick={() => {
                  if (userType === 'consultant') {
                    navigate('/profile');
                  }
                }}
                className="flex items-start relative w-full h-full "
              >
                <p>{localStorage.getItem('company_name') ?? ''}</p>
              </button>
            </div>

          </div>
        </div>

        <div className="flex-grow flex items-center justify-end gap-10">
          <div className="flex items-center gap-4 flex-shrink-0">
            <img alt="Logo Laborus" src={logoLaborus} className="h-[90px]" />
          </div>
          <div className="flex items-center gap-6 mobile:gap-2">
            <div className="mobile:flex hidden">
              <UserMenu />
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-wrap justify-between m-4 '>
        <ItemTextNumber text='Estándares mínimos' number={getMinimumStandards(numEmployees, riskLevel).toString()} />
        <ItemTextNumber text='Número de trabajadores' number={numEmployees.toString()} />
        <ItemTextNumber text='Nivel de riesgo' number={riskLevel} />
        <Notifications />
      </div>
      <span className='text-m text-primaryRed'>{errorMessage}</span>
    </header>
  );
};

export default Header;

