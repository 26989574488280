import { mainDivClassName } from "./MainDivClassName";

export const containerClassName = (pathName: string) => {

    if (pathName === '/evidence-home') {
        return 'grid place-items-start justify-items-center h-auto laptop:border laptop:border-white desktop:border desktop:border-white py-5'

    }
    else {
        return `grid place-items-start justify-items-center h-screen laptop:border laptop:border-white desktop:border desktop:border-white py-5 ${mainDivClassName}`;

    }
}