import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import ListEmployees from "../../components/uploadImagesAndVideo/ListEmployees";
import AddVideos from "../../components/uploadImagesAndVideo/AddVideos";
import { InitialInspection } from "../../components/inspection/InitialInspection";
import { useAppSelector } from "../../app/hooks";
import { useLocation } from "react-router-dom";

export function TabsCustomAnimation() {
  const location = useLocation();


  const allTabs = [
    {
      label: "Inspecciones de puestos de trabajo",
      value: "employeeImage",
      desc: (
        <div>
          <ListEmployees />
        </div>
      ),
    },
    {
      label: "Video de instalaciones de la empresa",
      value: "companyVideo",
      desc: (
        <div>
          <AddVideos />
        </div>
      ),
    },
    {
      label: "Inspección",
      value: "inspection",
      desc: (
        <div>
          <InitialInspection />
        </div>
      ),
    },
  ];

  const data = location.pathname === '/evidence-home'
    ? allTabs.filter(tab => tab.value !== 'employeeImage')
    : allTabs;

  const initialTabValue = location.pathname === '/evidence-home' ? 'companyVideo' : 'employeeImage';

  return (
    <div className="">
      <Tabs id="" value={initialTabValue}>
        <TabsHeader
          className=" p-3 flex items-center gap-3 overflow-x-auto"
          placeholder={''}
          indicatorProps={{
            className: "bg-primaryOrange h-1 rounded-t-lg bottom-0 mt-12"

          }}
        >
          {data.map(({ label, value }, index) => (
            <Tab
              key={value}
              value={value}
              placeholder={''}
              className={`
                  w-auto shrink-0 px-6 py-4
                  rounded-lg
                  font-bold text-m
                  border border-transparent
                  ui-selected:!bg-orange-50 ui-selected:!text-orange-500
                  ui-selected:border-b-2 ui-selected:border-b-orange-500
                  ui-selected:transform ui-selected:scale-105
                  ui-not-selected:text-gray-600 ui-not-selected:bg-white ui-not-selected:border-gray-200
                  ui-not-selected:hover:bg-gray-100 ui-not-selected:hover:text-gray-700
                  transition-all duration-300 ease-in-out
                  focus:outline-none text-secondaryBrown
                  whitespace-nowrap
                `}
            >
              <div className="flex items-center justify-center space-x-2">
                <div className={`
                    w-2 h-2 rounded-full
                    ui-selected:bg-white ui-selected:shadow-md
                    ui-not-selected:bg-transparent
                  `} />
                <span>{label}</span>
              </div>
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody
          className=""
          placeholder={''}
          animate={{
            initial: { opacity: 0, y: 10 },
            mount: { opacity: 1, y: 0 },
            unmount: { opacity: 0, y: 10 },
          }}
        >
          {data.map(({ value, desc }) => (
            <TabPanel
              key={value}
              value={value}
              className="min-h-[500px]"
            >
              {desc}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  );
}