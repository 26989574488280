// En Absenteeism.tsx

import { useEffect, useState } from 'react';
import CreateAbsenteeismModal, { CreateAbsenteeismFormData } from '../../components/absenteeism/CreateAbsenteeismModal';
import { AbsenceData, AbsenceResponse, AbsenteeismService } from '../../services/absenteeismService';
import MainLayout from '../../components/MainLayout';
import TextFieldDate from '../../components/TextFieldDate';
import TableAbsenteeism, { Column } from '../../components/TableAbsenteeism';
import SelectInput from '../../components/SelectInput';
import { absenceTypes } from '../../utils/AbsenteeismUtils';
import Button from '../../components/Button';
import { useAppSelector } from '../../app/hooks';

const columns: Column[] = [
  { id: 'employee_data', label: 'Nombre' },
  { id: 'absense_type', label: 'Tipo' },
  { id: 'description', label: 'Descripción' },
  { id: 'start_date', label: 'Fecha Inicio' },
  { id: 'end_date', label: 'Fecha Final' },
  { id: 'evidence_url', label: 'Evidencia' },
  { id: 'edit', label: 'Editar' },
  { id: 'delete', label: 'Eliminar' },
];



const Absenteeism = () => {
  const companyName = useAppSelector((state) => state.user.companyName);
  const companyId = useAppSelector((state) => state.user.companyId);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);;
  const [absenceType, setAbsenceType] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [absences, setAbsences] = useState<AbsenceResponse[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    setEndDate(null)
    setStartDate(null)
    fetchAbsences();
  }, [companyName, companyId]);

  const fetchAbsences = () => {

    AbsenteeismService.getAbsences(

      (response) => {
        setAbsences(response);

      },
      (error) => {
        console.error('Error al obtener las ausentismo:', error);
        setErrorMessage('Error al obtener las ausentismo.');
      },
      startDate || null,
      endDate || null,
      absenceType,
    );
  };

  const handleFilter = () => {
    fetchAbsences();
  };


  const handleCreateAbsence = (formData: CreateAbsenteeismFormData) => {
    setErrorMessage(null)
    setSuccessMessage(null)

    const absenceData = new AbsenceData(
      formData.absenceType,
      formData.description,
      formData.startDate,
      formData.endDate,
      formData.evidenceUrl,
      formData.employee
    );

    AbsenteeismService.createAbsence(
      absenceData,
      (response) => {
        console.log('ausentismo creada:', response);
        setSuccessMessage('Ausentismo creado con éxito.');
        setOpenModal(false);
        fetchAbsences()
        setTimeout(() => setSuccessMessage(null), 2000);

      },
      (error) => {
        console.error('Error al crear la ausentismo:', error);
        setErrorMessage('Error al crear la ausentismo.');
        setTimeout(() => setErrorMessage(null), 2000);

      }
    );
  };

  const handleUpdate = (formData: AbsenceResponse) => {
    setErrorMessage(null)
    setSuccessMessage(null)
    AbsenteeismService.updateAbsence(
      formData,
      () => {
        fetchAbsences()
        setSuccessMessage('Ausentismo actualizado con éxito.');
        setTimeout(() => setSuccessMessage(null), 2000);
      },
      (error) => {
        console.error('Error al actualizar la ausentismo:', error);
        setErrorMessage('Error al actualizar la ausentismo.');
        setTimeout(() => setErrorMessage(null), 2000);
      }
    );
  };

  const handleDelete = (id: number) => {
    setErrorMessage(null)
    setSuccessMessage(null)
    AbsenteeismService.deleteAbsence(
      id,
      () => {
        fetchAbsences()
        setSuccessMessage('Ausentismo eliminado con éxito.');
        setTimeout(() => setSuccessMessage(null), 2000);
      },
      (error) => {
        console.error('Error al obtener los datos:', error);
        setErrorMessage('Error al eliminar la ausentismo.');
        setTimeout(() => setErrorMessage(null), 2000);
      }
    );
  };


  return (
    <>
      <MainLayout>
        <div className='grid text-center items-center text-secondaryBrown'>
          <h1 className="text-4xl text-start font-bold mb-16 text-primaryOrange">Ausentismo</h1>
          <div className='grid place-content-start justify-items-start mb-16' >
            <Button
              onClick={() => setOpenModal(true)}
              text='Crear ausentismo'
            />
          </div>
          {successMessage && <span className="text-green-600">{successMessage}</span>}
          {errorMessage && <span className="text-red-600">{errorMessage}</span>}
          <h2 className='text-xxl text-start mb-4 text-primaryOrange'>Histórico de ausentismo</h2>
          <div className="flex gap-6 items-center text-start mb-10 mx-4">
            <TextFieldDate
              label="Fecha Inicio"
              value={startDate}
              onChange={(e) => setStartDate(e.target.valueAsDate)}
            />
            <TextFieldDate
              label="Fecha fin"
              value={endDate}
              onChange={(e) => setEndDate(e.target.valueAsDate)}
            />
            <SelectInput
              id="absence-type"
              label="Tipo de Ausentismo"
              value={absenceType}
              onChange={(e) => setAbsenceType(e.target.value)}
              options={absenceTypes.map(absenceType => ({ value: absenceType, label: absenceType }))}
            />
            <Button
              onClick={handleFilter}
              text='Filtrar'
            />

          </div>
          <TableAbsenteeism
            columns={columns}
            rows={absences}
            handleUpdate={handleUpdate}
            handleDeleteClick={handleDelete}

          />

          <CreateAbsenteeismModal
            open={openModal}
            onClose={() => {
              console.log('Closing modal');
              console.log(openModal);

              setOpenModal(false);
            }}
            onCreate={handleCreateAbsence}
          />

        </div>

      </MainLayout>


    </>
  );
};

export default Absenteeism;
