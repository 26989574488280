import { useAppSelector } from "../../app/hooks";
import MainLayout from "../../components/MainLayout";
import { TabsCustomAnimation } from "./FinalRegistration";

export function InspectionHome() {
    return (
        <MainLayout>
            <TabsCustomAnimation />
        </MainLayout>
    );
}

// Si es company u otro tipo de usuario